<template>
  <div>
    <v-card class="pa-5">
      <v-row>
        <v-col cols="6">
          <v-select
            :label="$t('systemSettings.permissions.selectRole')"
            v-model="roleType"
            :items="roleTypes"
            item-text="description"
            item-value="id"
            @change="getPermissions(roleType)"
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="template" class='my-2'>
        <v-progress-linear v-if="loading" class='mx-2' absolute indeterminate></v-progress-linear>
        <v-col cols=12 lg=6 v-for="category in permissionCategories" :key="category.id">
          <v-card>
            <v-card-title>{{category.description}}</v-card-title>
            <v-card-text>  <v-row dense v-for='permission in groupedPermissions[category.id]' :key='permission.name'>
            <v-col>
              <div class='d-flex grey lighten-5 pl-2 pr-2'>
                <span :class="[permission.isParent ? 'mt-auto mb-auto font-weight-black' : 'mt-auto mb-auto']">{{permission.displayName}}</span>
                <v-switch :disabled='loading' class='ml-auto mt=auto mb-auto' v-model='permission.active' @change="permissionChange(permission)"></v-switch>
              </div>
            </v-col>
            </v-row>
          </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Services from '@/services'
import { groupBy } from '@/utils/helpers'
export default {
  name: 'Permissions',
  props: {
    user: Object
  },
  data () {
    return {
      loading: false,
      roleType: null,
      permissions: null,
      template: null
    }
  },
  computed: {
    permissionCategories () {
      return this.$store.getters.enums.permissionCategories
    },
    roleTypes () {
      return this.$store.getters.enums.customerServiceRoleTypes
    },
    groupedPermissions () {
      if (!this.template || !this.template.permissions) {
        return []
      }
      return groupBy(this.template.permissions, 'category')
    }
  },
  methods: {
    async getPermissions (roleId) {
      this.loading = true
      const response = await Services.get(`UserManagement/GetRolePermissionsTemplate?Role=${roleId}`)
      this.template = response.data
      this.loading = false
    },
    async permissionChange (permission) {
      try {
        this.loading = true
        await Services.put('UserManagement/UpdateRolePermissionsTemplate', {
          templateId: this.template.templateId,
          permissions: this.template.permissions
        })
        await this.$store.dispatch('setSuccessNotification', `${this.$t('systemSettings.permissions.changed')} ${permission.displayName}`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
